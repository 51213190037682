import axios from 'axios'

import {
  VESSEL_LIST,
  VESSEL_DETAIL,
  UPDATE_VESSEL,
  ADD_VESSEL,
  VESSEL_INFO,
  DELETE_VESSEL,
  VESSEL_MANAGEMENT,
  VESSEL_VISITATION,
  PSC_INDEX,
  VESSEL_FILE,
  VESSEL_ACCIDENT_INCIDENT_YEARLY_COUNTS,
  VESSEL_ACCIDENT_INCIDENT_YEARLY_COUNTS_LIST,
  DOWNLOAD_VESSEL_ACCIDENT_INCIDENT_YEARLY_COUNTS, SUMMARY_VESSEL_LIST, DOWNLOAD_SUMMARY_MANAGING_VESSEL, VESSEL_SMS_RECORD,
} from './ConstantService'
import { GlobalService } from './GlobalService'
import { UrlService } from '@/services/UrlService'

export const VesselService = {
  async getDashboardVesselManagement() {
    return await axios.get(VESSEL_MANAGEMENT).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSELS MANAGEMENT SERVICE')
      return false
    })
  },
  async downloadManagingVesselSummary() {
    return await axios({
      url: DOWNLOAD_SUMMARY_MANAGING_VESSEL,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        return response ?? false
      }).catch(error => {
        GlobalService.handleError(error, 'DOWNLOAD MANAGING VESSEL SUMMARY SERVICE')
        return false
      })
  },
  async getVesselList (params) {
    const queryString = UrlService.setQueryString(params)
    return await axios.post(VESSEL_LIST + '?page=' + params.page, {params}).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSELS LIST SERVICE')
      return false
    })
  },
  async getVesselSummaryList (params) {
    return await axios.post(SUMMARY_VESSEL_LIST, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSELS LIST SERVICE')
      return false
    })
  },
  async getVesselDetail (params) {
    const url = VESSEL_DETAIL + `/${params}`
    return await axios.get(url).then(response => {
      // console.log(response.data)
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL DETAIL SERVICE')
      return false
    })
  },

  async getVisitation (vessel_id) {
    const url = VESSEL_VISITATION.replace('{vessel_id}', vessel_id)
    return await axios.get(url).then(response => {
      return response.data.data ?? false

    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL DETAIL SERVICE')
      return false
    })
  },

  async getSmsRecord (vessel_id) {
    const url = VESSEL_SMS_RECORD.replace('{vessel_id}', vessel_id)
    return await axios.get(url).then(response => {
      return response.data.data ?? false

    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL DETAIL SERVICE')
      return false
    })
  },

  async getFiles (vessel_id) {
    const url = VESSEL_FILE.replace('{vessel_id}', vessel_id)
    return await axios.get(url).then(response => {
      return response.data.data ?? false

    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL DETAIL SERVICE')
      return false
    })
  },

  async createFolder (params) {
    const url = VESSEL_VISITATION.replace('{vessel_id}', params.vessel_id)

    const response = await GlobalService.ajax('post', url, params);
    if (response.status !== 200) {
      throw Error(response.message)
    }

    return response?.data

  },

  async createSMSFolder (params) {
    const url = VESSEL_SMS_RECORD.replace('{vessel_id}', params.vessel_id)

    const response = await GlobalService.ajax('post', url, params);
    if (response.status !== 200) {
      throw Error(response.message)
    }

    return response?.data

  },

  async deleteFile (params) {
    const url = VESSEL_VISITATION.replace('{vessel_id}', params.vessel_id) + '/delete'


    const response = await GlobalService.ajax('post', url, params);

    if (response.status !== 200) {
      throw Error(response.message)
    }

    return response?.data


  },

  async getVesselInfo(params){
    return await axios.post(VESSEL_INFO, params)
      .then(response => {
        return response.data.vesselInfo ? response.data.vesselInfo : false;
      })
      .catch(error => {
        GlobalService.handleError(error, 'GET VESSEL INFO SERVICE');
        return false;
      });
  },

  async updateVessel (params) {
    return await axios.post(UPDATE_VESSEL, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE VESSEL SERVICE')
      return false
    })
  },
  async addVessel (params) {
    return await axios.post(ADD_VESSEL, params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'ADD VESSEL SERVICE')
      return false
    })
  },

  async deleteVessel(params){
    return await axios.post(DELETE_VESSEL,{vesselId:params}).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'DELETE VESSEL SERVICE')
      return false
    })
  },

  async getVesselAccidentIncidentYearlyCounts(params) {
    const queryString = UrlService.setQueryString(params)
    return await axios.get(VESSEL_ACCIDENT_INCIDENT_YEARLY_COUNTS + queryString).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL ACCIDENT INCIDENT YEARLY COUNTS SERVICE')
      return false
    })
  },

  async getVesselAccidentIncidentListFromYearlyCount(params) {
    const queryString = UrlService.setQueryString(params)
    return await axios.get(VESSEL_ACCIDENT_INCIDENT_YEARLY_COUNTS_LIST + queryString).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET VESSEL ACCIDENT INCIDENT YEARLY COUNTS LIST SERVICE')
      return false
    })
  },
  async downloadVesselAccidentIncidentYearlyCount(params) {
    const queryString = UrlService.setQueryString(params)
    return await axios({
      url: DOWNLOAD_VESSEL_ACCIDENT_INCIDENT_YEARLY_COUNTS + queryString,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        return response ?? false
      }).catch(error => {
        GlobalService.handleError(error, 'DOWNLOAD ACCIDENT INCIDENT YEARLY COUNT SERVICE')
        return false
      })
  },
}

export default { VesselService }
